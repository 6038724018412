.theme-novacolor.light {
  --background: hsl(86 100% 100%);
  --foreground: hsl(86 5% 10%);

  --card: hsl(86 50% 100%);
  --card-foreground: hsl(86 5% 15%);

  --popover: hsl(86 100% 100%);
  --popover-foreground: hsl(86 100% 10%);

  --primary: hsl(86 54.4% 51%);
  --primary-foreground: hsl(0 0% 100%);

  --secondary: hsl(86 30% 90%);
  --secondary-foreground: hsl(0 0% 0%);

  --muted: hsl(48 30% 95%);
  --muted-foreground: hsl(86 5% 40%);

  --accent: hsl(48 30% 90%);
  --accent-foreground: hsl(86 5% 15%);

  --destructive: hsl(0 100% 50%);
  --destructive-foreground: hsl(86 5% 100%);

  --border: hsl(86 30% 82%);
  --input: hsl(86 30% 50%);
  --ring: hsl(86 54.4% 51%);
  --radius: 0.25rem;
}

.theme-novacolor.dark {
  --background: hsl(86 0% 10%);
  --foreground: hsl(86 5% 100%);

  --card: hsl(86 0% 10%);
  --card-foreground: hsl(86 5% 100%);

  --popover: hsl(86 50% 5%);
  --popover-foreground: hsl(86 5% 100%);

  --primary: hsl(86 54.4% 51%);
  --primary-foreground: hsl(0 0% 100%);

  --secondary: hsl(86 30% 20%);
  --secondary-foreground: hsl(0 0% 100%);

  --muted: hsl(48 0% 25%);
  --muted-foreground: hsl(86 5% 65%);

  --accent: hsl(48 30% 25%);
  --accent-foreground: hsl(86 5% 95%);

  --destructive: hsl(0 100% 50%);
  --destructive-foreground: hsl(86 5% 100%);

  --border: hsl(86 30% 50%);
  --input: hsl(86 30% 50%);
  --ring: hsl(86 54.4% 51%);
  --radius: 0.5rem;
}
